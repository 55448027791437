<template>
  <div class="row">
    <div class="col">
      <b-modal ref="emailmodal" size="lg" no-enforce-focus hide-header>
        <form @submit.prevent="saveEmail">
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
            <blueprint-search-input
              id="properties"
              v-model="selecteditem.propertyIds"
              :state="state('propertyIds')"
              :multiple="true"
              placeholder="Type to search Properties"
              label="name"
              track-by="id"
              api-route-path="inventory/properties"
            />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Subject" label-for="subject">
            <b-form-input id="subject" v-model="selecteditem.subject" :state="state('subject')" type="text" placeholder="Subject" />
          </b-form-group>
          <b-form-textarea
            v-model="selecteditem.body"
            :state="state('subject')"
            rows="3"
            max-rows="6"
          />
          <input type="submit" style="visibility: hidden;">
        </form>
        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="success" @click="saveEmail()">
            Save
          </b-button>
          <b-button variant="dark" @click="cancel()">
            Cancel
          </b-button>
        </template>
      </b-modal>
      <b-modal ref="smsmodal" size="lg" no-enforce-focus hide-header>
        <form @submit.prevent="saveSMS">
          <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
            <blueprint-search-input
              id="properties"
              v-model="selecteditem.propertyIds"
              :state="state('propertyIds')"
              :multiple="true"
              placeholder="Type to search Properties"
              label="name"
              track-by="id"
              api-route-path="inventory/properties"
            />
          </b-form-group>
          <b-form-textarea
            v-model="selecteditem.body"
            :state="state('subject')"
            rows="3"
            max-rows="6"
          />
          <input type="submit" style="visibility: hidden;">
        </form>
        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="success" @click="saveSMS()">
            Save
          </b-button>
          <b-button variant="dark" @click="cancel()">
            Cancel
          </b-button>
        </template>
      </b-modal>

      <b-card title="Messages">
        <blueprint-data
          ref="data"
          api-route-path="jobs"
          :where="where"
          :fields="fields"
          no-edit
          no-actions
        >
          <template v-slot:status="{ item }">
            <b-badge v-if="item.status === 'ERROR'" variant="danger">
              Error
            </b-badge>
            <b-badge v-if="item.status === 'CANCELED'" variant="warning">
              Canceled
            </b-badge>
            <b-badge v-if="item.status === 'SCHEDULED'" variant="warning">
              Scheduled
            </b-badge>
            <b-badge v-if="item.status === 'RESCHEDULED'" variant="warning">
              Rescheduled
            </b-badge>
            <b-badge v-if="item.status === 'OK'" variant="success">
              Done
            </b-badge>
            <small v-if="item.autoResceduleCount > 0">
              Auto rescheduled count: <b-badge variant="warning">{{ item.autoResceduleCount }}</b-badge>
            </small>
          </template>
          <template v-slot:output="{ item }">
            <b-badge v-if="item.output && item.output.error && item.output.message" variant="danger">
              {{ item.output.message }}
            </b-badge>
            <small v-else><code>{{ item.output }}</code></small>
          </template>
          <template v-slot:name="{ item }">
            <b-badge variant="dark">
              {{ item.name }}
            </b-badge>
          </template>
          <template v-slot:duration="{ item }">
            <span v-if="item.duration">{{ item.duration }}s</span>
          </template>
          <template v-slot:scheduled="{ item }">
            <span>{{ item.scheduled | moment('lll') }}</span>
          </template>
          <template v-slot:createdAt="{ item }">
            <span>{{ item.createdAt | moment('lll') }}</span>
          </template>
          <template v-slot:attrs="{ item }">
            {{ item.attrs.subject }} <br>
            {{ item.attrs.body }}
          </template>
          <template v-slot:props="{ item }">
            <span v-if="item.attrs.propertyIds">
              <span v-for="propertyId of item.attrs.propertyIds" :key="propertyId">
                <strong><ObjectName :id="propertyId" object="inventory/properties" /></strong><br>
              </span>
            </span>
          </template>
          <template v-slot:new="{ }">
            <b-button variant="success" @click="newEmail">
              <fa-icon icon="plus" /> New e-mail
            </b-button>
            <b-button variant="success" @click="newSMS">
              <fa-icon icon="plus" /> New SMS
            </b-button>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import ObjectName from '../../../pages/app/tasks/components/object-name.vue';

export default {
  components: {
    ObjectName
  },
  data () {
    return {
      where: {
       name: { $in: ['blk send bookings email', 'blk send bookings sms'] }
      },
      selecteditem: {},
      errors: {},
      status: null,
      fields: [
        {
          key: 'name',
          sortable: true,
          slot: true,
        },
        {
          key: 'attrs',
          sortable: false,
          slot: true,
        },
        {
          key: 'props',
          sortable: false,
          slot: true,
        },
        {
          key: 'createdAt',
          label: 'Created',
          sortable: true,
          slot: true,
        },
        {
          key: 'scheduled',
          sortable: true,
          slot: true,
        },
        {
          key: 'status',
          sortable: true,
          slot: true,
        },
        {
          key: 'output',
          sortable: false,
          slot: true,
        },
        {
          key: 'duration',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  methods: {
    state (item) {
      return (this.errors[item] ? false : null);
    },
    err (err) {
      let message = err.response && err.response.data ? err.response.data.message || err.message : err.message;
      this.error('Error', message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    newEmail () {
      this.errors = {};
      this.selecteditem = {};
      this.$refs.emailmodal.show();
    },
    newSMS () {
      this.errors = {};
      this.selecteditem = {};
      this.$refs.smsmodal.show();
    },
    saveEmail () {
      this.$http.post(`bookings/messaging/blk-email`, this.selecteditem)
        .then((res) => {
          this.$refs.emailmodal.hide();
          this.key = res.data.key;
          this.item = {};
        })
        .then(() => this.$refs.data.getData())
        .catch(this.err);
    },
    saveSMS () {
      this.$http.post(`bookings/messaging/blk-sms`, this.selecteditem)
        .then((res) => {
          this.$refs.smsmodal.hide();
          this.key = res.data.key;
          this.item = {};
        })
        .then(() => this.$refs.data.getData())
        .catch(this.err);
    },
  }
};
</script>
